import {
	Module,
	VuexModule,
	getModule,
	Mutation,
	Action,
	MutationAction,
} from "vuex-module-decorators";
import {
	Messages,
	Message as _Message,
	MessagePayload,
} from "vue-advanced-chat";
import { store } from "@/store";
import { socket } from "@/main";
import { DateTime } from "ts-luxon";
import { TicketModule } from "./Ticket";
import {
	getRelativeTime,
	getTypeFile,
	isValidUrl,
	mediaToUrl,
} from "@/util/utils";
import { IMessage, RequestMessage, TemplatePayload } from "@/types/messages";
import { Files, FilesModule } from "./Files";
import { AlertModule } from "./Alert";
import { AppModule } from "./app";
import { env } from "@/config";
import { ConversationModule } from "./Conversation";
import { nextTick } from "vue";

function getUsersColors(users: Set<string>) {
	let i = 0;
	return [...users].reduce((acc, curr) => {
		acc[curr] = env.Colors[i];
		i = env.Colors?.[i + 1] ? i + 1 : 0;
		return acc;
	}, {} as { [k: string]: string });
}

@Module({ dynamic: true, namespaced: true, store, name: "message" })
export class Message extends VuexModule {
	currentMessage = "";
	users: Set<string> = new Set<string>();
	messages: Record<string, Messages> = {};
	fileUploadVisible = false;
	loading = false;
	append = false;
	uploading: number[] = [];
	gifs: string[] = [];
	showUpperLoader = false;

	static parse(m: IMessage, i?: string | number, isFirst?: boolean): _Message {
		const customTypes = ["buttons", "cards", "email"];
		const status = m.channel?.interface === "WHATSAPP_OFFICIAL";
		let customColorCloseSystemMessage = {};
		if (m.origin === "system") {
			const closeSystemMessageRegex = new RegExp("finalizó el ticket");
			const closeSystemMessage = closeSystemMessageRegex.test(m.body.text);
			customColorCloseSystemMessage = closeSystemMessage
				? {
						backgroundColor: "#f56c6c",
						fontColor: "white",
				  }
				: {};
		}

		return {
			_id: m._id,
			id: m.id,
			indexId: i ?? m.channel._id,
			date: getRelativeTime(m.updatedAt ?? m.createdAt),
			isoDate: m.createdAt ?? m.updatedAt,
			senderId: m?.client?._id ?? m.agent?._id ?? `system:${m.origin}`,
			content: m.body.text ?? "",
			username:
				m.origin === "system"
					? "Sistema"
					: m.origin === "bot"
					? "BOT"
					: m?.client?.customer?.name ?? m?.agent?.auth.username,
			timestamp: DateTime.fromISO(m.createdAt).toFormat("hh:mm a"),
			system: m.origin === "system",
			origin: m.origin,
			saved: status ? Boolean(m.sentAt) : true,
			isFirst,
			files: m.body.attachment
				? customTypes.includes(m.body.attachment.type)
					? [
							{
								name: m.body.attachment.name,
								type: m.body.attachment.type,
								cards: m.body.attachment.cards ?? undefined,
								buttons: m.body.attachment.buttons ?? undefined,
							},
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  ]
					: [
							{
								name: m.body.attachment.name,
								type: getTypeFile(
									m.body.attachment?.url ?? "",
									m.channel.interface === "INSTAGRAM" ||
										m.channel.interface === "WHATSAPP_OFFICIAL"
										? m.body.attachment.type
										: ""
								).type,
								url: mediaToUrl(m.body.attachment.url ?? "", env.attachmentURL),
								preview: mediaToUrl(
									m.body.attachment.url ?? "",
									env.attachmentURL
								),
							},
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  ]
				: undefined,
			distributed: status ? Boolean(m.deliveredAt) : true,
			seen: status ? Boolean(m.readAt) : true,
			deleted: false,
			failure: status || m.failed ? m.failed : undefined,
			disableActions: false,
			disableReactions: true,
			...customColorCloseSystemMessage,
		};
	}
	static preview(
		m: Partial<
			Pick<MessagePayload, "content" | "files" | "senderId" | "origin">
		>,
		id: string,
		index: number
	): _Message {
		const createdAt = new Date().toISOString();
		return {
			content: m.content ?? "",
			date: getRelativeTime(createdAt),
			_id: id,
			indexId: index,
			senderId: m.senderId ?? "",
			files:
				m.files?.map(f => ({
					name: f.name,
					type: f.type,
					url: f.localUrl,
					preview: f.localUrl,
					progress: 0,
				})) ?? [],
			timestamp: DateTime.fromISO(createdAt).toFormat("hh:mm a"),
			system: m.origin === "system",
			isFirst: false,
			seen: false,
			saved: false,
			distributed: false,
			disableActions: true,
			disableReactions: true,
		};
	}
	// Getters
	get loaded() {
		return !this.loading;
	}
	get loadingMoreMessage() {
		return this.loading && this.append;
	}
	get getMessages() {
		return TicketModule.currentRoomID
			? this.messages[TicketModule.currentRoomID]
			: [];
	}
	get allMessagesLoaded() {
		// Shows when show the top loader in the message imbox
		return !this.showUpperLoader;
	}
	get CurrentMessage() {
		return this.currentMessage;
	}

	get lastMessage() {
		return this.messages[TicketModule.currentRoomID ?? ""]?.[
			this.messages[TicketModule.currentRoomID ?? ""]?.length - 1
		];
	}

	get firstMessage() {
		return this.messages[TicketModule.currentRoomID ?? ""]?.[0];
	}

	get usersColors() {
		return getUsersColors(this.users);
	}

	get ShowUpperLoader(): boolean {
		return this.showUpperLoader;
	}

	// Mutations
	@Mutation
	deleteAllMessages() {
		this.messages = {};
	}
	@Mutation
	deleteFromTicket(id: string) {
		if (this.messages[id]) delete this.messages[id];
	}
	@Mutation
	refreshCurrentMessage(message: string) {
		this.currentMessage = message;
	}

	@Mutation
	SOCKET_MESSAGE_LIST_PAGED(messages: IMessage[]) {
		this.showUpperLoader = !this.showUpperLoader;
		if (messages.length < 50) this.showUpperLoader = false;
		else this.showUpperLoader = true;
		if (this.append) messages.shift();
		else {
			this.users.clear();
			this.messages = {};
		}
		ConversationModule.setConversation({
			id: TicketModule?.currentRoom?.cId ?? "",
			expireAt: TicketModule.currentRoom?.expireAt
				? new Date(TicketModule.currentRoom.expireAt)
				: undefined,
			replied: TicketModule.currentRoom?.replied,
			interface: TicketModule.currentTicket?.channel.interface,
		});
		if (messages.length)
			this.messages[TicketModule.currentRoomID ?? ""] = [
				...messages.reverse().map((m, i) => {
					this.users.add(
						m?.client?._id ?? m.agent?._id ?? `system:${m.createdAt}`
					);
					const _m = Message.parse(
						m,
						i,
						(messages[i - 1]?.client?._id !== m.client?._id ||
							messages[i - 1]?.agent?._id !== m.agent?._id) &&
							messages[i - 1]?.origin !== m.origin
					);
					return _m;
				}),
				...(this.append || !messages.length
					? this.messages[TicketModule.currentRoomID ?? ""] ?? []
					: []),
			];
		this.loading = false;
		nextTick().then(() => {
			const listMessages = this.messages[TicketModule.currentRoomID ?? ""];
			const totalMessages = listMessages.length;
			if (totalMessages > 99)
				this.messages[TicketModule.currentRoomID ?? ""].splice(
					totalMessages - messages.length
				);
		});
	}

	@Mutation
	SOCKET_INVERTED_MESSAGE_LIST_PAGED(messages: IMessage[]) {
		if (!this.append) this.messages = {};
		if (messages.length)
			this.messages[messages[0].ticket] = [
				...(this.append || !messages.length
					? this.messages[TicketModule.currentRoomID ?? ""] ?? []
					: []),
				...messages.map((m, i) => {
					this.users.add(
						m?.client?._id ?? m.agent?._id ?? `system:${m.createdAt}`
					);
					const _m = Message.parse(
						m,
						i,
						(messages[i - 1]?.client?._id !== m.client?._id ||
							messages[i - 1]?.agent?._id !== m.agent?._id) &&
							messages[i - 1]?.origin !== m.origin
					);
					return _m;
				}),
			];
		this.loading = false;
	}

	@Mutation
	SOCKET_MESSAGE_NEW(message: IMessage) {
		if (TicketModule.currentRoomID !== message.ticket) {
			return;
		}
		this.users.add(
			message?.client?._id ??
				message.agent?._id ??
				`system:${message.createdAt}`
		);
		const parsedMessage = Message.parse(
			message,
			this.messages[TicketModule.currentRoomID ?? ""].length,
			this.messages[TicketModule.currentRoomID ?? ""][
				this.messages[TicketModule.currentRoomID ?? ""].length - 1
			]?.senderId !== (message.client?._id ?? message.agent?._id)
		);
		if (!message.agent) AppModule.notificationsElement["NEW_MESSAGE"]?.play();

		const upMsg = this.uploading.findIndex(
			id =>
				this.messages[TicketModule.currentRoomID ?? ""][id]._id === message._id
		);
		if (upMsg !== -1) {
			const msg =
				this.messages[TicketModule.currentRoomID ?? ""][this.uploading[upMsg]];
			if (msg.files?.[0]) {
				msg.files[0].url = mediaToUrl(
					message.body.attachment.url ?? "",
					env.attachmentURL
				);
				msg.files[0].progress = undefined;
			}
			if (message.channel.interface !== "WHATSAPP_OFFICIAL")
				msg.saved = msg.distributed = msg.seen = true;
			this.uploading.splice(upMsg, 1);
		} else this.messages[TicketModule.currentRoomID ?? ""].push(parsedMessage);
	}

	@Mutation
	updateProgress({ a, i }: { [key: string]: number }) {
		const file = this.messages[TicketModule.currentRoomID ?? ""][i]?.files?.[0];
		if (file)
			this.messages[TicketModule.currentRoomID ?? ""][i].files = [
				{
					...file,
					progress: a,
				},
			];
	}

	@Mutation
	addPreviewMessages(messages: MessagePayload & { ids: string[] }) {
		const previews =
			messages.files?.map((m, i) => {
				this.uploading.push(
					this.messages[TicketModule.currentRoomID ?? ""].length + i
				);
				return Message.preview(
					{ files: [m], senderId: messages.senderId },
					messages.ids[i],
					this.messages[TicketModule.currentRoomID ?? ""].length + i
				);
			}) ?? [];
		if (messages.content)
			previews.push(
				Message.preview(
					{
						content: messages.content,
						senderId: messages.senderId,
						origin: messages.origin,
					},
					messages.ids.at(-1) ?? "",
					this.messages[TicketModule.currentRoomID ?? ""].length +
						messages.files?.length
				)
			) &&
				this.uploading.push(
					this.messages[TicketModule.currentRoomID ?? ""].length +
						(messages.files?.length ?? 0)
				);
		this.messages[TicketModule.currentRoomID ?? ""].push(...previews);
	}
	// Actions
	@Action
	requestTicketsFromClient({
		token,
		client,
		page = 1,
		limit = 5,
		ticketId = undefined,
	}: {
		token: string;
		client: string;
		page?: number;
		limit?: number;
		ticketId?: string;
	}) {
		socket.emit("TICKETS_FROM_CLIENT", {
			client,
			token,
			page,
			limit,
			ticketId,
		});
	}

	@MutationAction
	async requestMessages({
		ticket,
		token,
		date,
		reset,
		bottonLoad = false,
	}: RequestMessage) {
		if (bottonLoad)
			socket.emit("INVERTED_MESSAGE_PAGE", {
				afterDate: date,
				token,
				ticket,
			});
		else {
			const historical =
				(TicketModule.Filter === "claimed" || TicketModule.Filter === "open") &&
				!ConversationModule.showControl &&
				(TicketModule.currentRoom?.newestRoom === undefined ||
					TicketModule.currentRoom?.newestRoom) &&
				env.historicalTicket;
			socket.emit("MESSAGE_PAGE", {
				beforeDate: date,
				token,
				ticket,
				historical,
			});
		}
		return {
			loading: true,
			append: !reset,
		};
	}

	@Mutation
	async SOCKET_MESSAGE_STATUS(event: {
		_id: string;
		messageId: string;
		status: string;
		ticket: string;
	}) {
		if (TicketModule.currentRoomID !== event.ticket) return;

		let i = this.messages[TicketModule.currentRoomID ?? ""].length - 1;
		for (i; i >= 0; i--) {
			if (this.messages[TicketModule.currentRoomID ?? ""][i]._id === event._id)
				break;
		}
		if (event.status === "queued") return;
		const status =
			event.status === "sent"
				? "saved"
				: event.status === "delivered"
				? "distributed"
				: event.status === "read"
				? "seen"
				: event.status === "deleted"
				? "deleted"
				: "failure";
		console.log(status);
		if (status !== "failure")
			this.messages[TicketModule.currentRoomID ?? ""][i][status] = true;
		else
			this.messages[TicketModule.currentRoomID ?? ""][i][status] =
				"Fallo al enviar mensaje";
	}

	@Action
	async sendTemplate(payload: {
		message: Partial<IMessage["body"]>;
		template: TemplatePayload;
		token: string;
	}) {
		socket.emit("TEMPLATE_NEW", {
			ticket: TicketModule.currentRoomID,
			...payload,
		});
	}

	@Action
	async sendMessage(payload: MessagePayload) {
		const { error, success } = Files.generateId(
			payload.files,
			!!payload.content
		);
		if (error?.message || !success?.response) {
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: error?.message ?? "",
				type: "error",
			});
			return;
		}
		this.addPreviewMessages({ ...payload, ids: success.response });

		const uploadedFiles = await FilesModule.uploadFiles({
			files: payload.files,
			ids: this.uploading,
		});

		if (payload.origin === "system") {
			socket.emit("SYSTEM_MESSAGE", {
				token: payload.token,
				ticket: TicketModule.currentRoomID,
				message: payload.content,
				id: success.response,
			});
			return;
		}

		let private_reply = TicketModule.privateReplySubmit
			? TicketModule.privateReplyStatus
			: null;
		if (TicketModule.publicReplySubmitted) private_reply = true;

		socket.emit("MESSAGE_NEW", {
			private_reply,
			attachments: uploadedFiles.map((f, i) => ({
				name: payload.files[i].name.replaceAll(".", "_"),
				type: Files.getType(payload.files[i].type),
				url: f.data.url,
				// url: mediaToUrl(f.data.url, env.attachmentURL),
			})),
			text: payload.content,
			ticket: TicketModule.currentRoomID,
			token: payload.token,
			id: success.response,
		});

		if (TicketModule.privateReplySubmit && TicketModule.privateReplyStatus) {
			TicketModule.deletePrivateReplyStatus({
				roomId: TicketModule.currentRoomID ?? "",
			});
		}
	}
}

export const MessageModule = getModule(Message);
